<template>
  <div>
    <div class="page-wrapper">
      <div class="m-t-40">
        <div class="authentication-box">
          <div class="text-center">
            <router-link to="/">
              <img src="../../assets/images/gurumandala-logo.png" alt="" style="width: 50%;">
            </router-link>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <div class="text-center">
                <h4>RESET PASSWORD</h4>
                <h6>Enter your Email and Password </h6>
              </div>
              <form class="theme-form">

                <div class="form-group">
                  <label class="col-form-label pt-0">Mobile Number:</label>
                  <div role="group" class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text pb-1">94</div>
                    </div>
                    <b-form-input :readonly="form.otp_hash !== null" v-model="form.phone_mobile" maxlength="9" class="form-control" type="text" autofocus required></b-form-input>
                  </div>
                  <sub class="text-muted">Ex: 94 771112233 (last 9 digits)</sub>
                </div>

                <template v-if="form.otp_hash === null">
                  <div class="form-group mt-3 mb-0">
                    <button @click.prevent="sendOTP" class="btn btn-danger btn-block" type="button">Send Verification Code</button>
                  </div>
                </template>
                <template v-else>
                  <div class="form-group">
                    <label class="col-form-label pt-0">Verification Code (OTP):</label>
                    <input v-model="form.otp" class="form-control border" type="text" autofocus required>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label pt-0">New Password:</label>
                    <b-form-input v-model="form.password" class="form-control border" type="password" autofocus required></b-form-input>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label pt-0">Confirm Password:</label>
                    <b-form-input v-model="form.password_confirmation" class="form-control border" type="password" autofocus required></b-form-input>
                  </div>
                  <div class="form-group mt-3 mb-0">
                    <button @click.prevent="submitForm" class="btn btn-danger btn-block" type="button">Reset Password</button>
                  </div>
                </template>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "reset_password",
  data() {
    return {
      last_otp_at: 0,
      step: 0,
      form: {
        phone_mobile: '',
        password: '',
        password_confirmation: '',
        otp_hash: null,
        otp: null
      }
    }
  },
  methods: {
    sendOTP(){
      if((30+this.last_otp_at) < new Date().getTime()) {
        this.form.otp_hash = null
        this.$store.dispatch('auth/sendPwdResetOTP', this.form)
            .then((response) => {
              console.log("RESP", response)
              if (response.data.success) {
                this.form.otp_hash = response.data.data.hash
              } else {
                console.log(response)
                this.$toasted.error(response.data.message)
              }
            }).catch((errors) => {
              console.log("ERROR", errors)
              this.errorBag = errors.message
              this.$toasted.error(errors.message)
        })

        // if( response.success ) {
        //   await this.$toasted.show('Successfully logged in',{
        //     fullWidth: true,
        //     fitToScreen: true,
        //     position: 'top-center',
        //     type : 'success',
        //   })
        // } else {
        //   this.errorBag = response.data
        //   await this.$toasted.error(response.message)
        // }
        //
        // ++this.step

      } else {
        this.$toasted.info('Please try again in 5 minutes.')
      }
      this.last_otp_at = new Date().getTime();
    },
    async submitForm() {
      await this.$store.dispatch('auth/resetPWD', this.form)
          .then((response) => {
            if(response.data.success) {
              this.$toasted.success(response.data.message)
            } else {
              this.$toasted.error(response.data.message)
            }
          }).catch((errors) => {
            console.log(errors.message)
            this.$toasted.error("Password reset failed")
          })

    }
  }
}
</script>

<style scoped>

</style>
